import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  Domain,
  Entity,
  OptionType,
  QUESTION_TYPES_ENUM,
  Question as QuestionType,
  VALIDATION_DATE_ENUM,
  VALIDATION_TEXT_ENUM,
  ValidationDateType,
  ValidationTextType,
  getAllDomains,
  getDomainsByKey,
  getEntitiesByType,
  useBreakpoint,
} from '@laborability/commons';
import { RadioGroup, Stack } from '@mui/material';
import { LBTButton } from '../Button';
import { IconDislikeComponent, IconLikeComponent } from '../Icons';
import LBTTextField from '../TextField';
import LBTSelect from '../Select';
import LBTSwitch from '../Switch';
import { IconsStyle } from '../../enums';
import { LBTCheckboxButton, LBTRadioButton } from '../ChoiceButton';
import { Fragment, useEffect, useState } from 'react';
import LBTSpacer from '../Spacer';
import LBTDatePicker from '../Datepicker';
import dayjs from 'dayjs';
import { Answer, findAnswer } from './Page';
import LBTAutocomplete from '../Autocomplete';
import LBTLabel from '../Label';

export type ListValue = { type: OptionType; value: number[] };
export type Value = number | boolean | string | ListValue;

interface QuestionProps {
  question: QuestionType;
  value?: Value;
  questions: {
    question_id: number;
    entity_id: number;
    answer_value: any;
    question: QuestionType;
    options: any;
  }[];
  values: Answer[];
  handleChange: (value: any) => void;
  setLoader: React.Dispatch<React.SetStateAction<number>>;
  setError: React.Dispatch<React.SetStateAction<number[]>>;
}

interface FieldProps {
  question: QuestionType;
  value: Value;
  handleChange: (value: any) => void;
  setLoader?: React.Dispatch<React.SetStateAction<number>>;
  setError?: React.Dispatch<React.SetStateAction<number[]>>;
  filter?: number;
}

async function fetchOptions(
  optionType: OptionType,
  id: string,
  filter?: number,
): Promise<(Domain | Entity)[]> {
  let res;
  if (optionType === OptionType.domain) {
    if (filter) res = await getAllDomains({ parent_id: filter });
    else res = await getDomainsByKey({ id: id });
  } else res = await getEntitiesByType({ entity_type: id });

  return res?.data?.items ?? [];
}

export default function Question({
  question,
  value,
  questions,
  values,
  handleChange,
  setLoader,
  setError,
}: QuestionProps) {
  const filterId = questions.find(
    item =>
      item?.question?.question_meta?.option_type === OptionType.domain &&
      item?.question?.question_meta?.options ===
        question?.question_meta?.option_filter,
  );
  const filter = filterId?.question_id
    ? (
        findAnswer(values, filterId.question_id, filterId.entity_id)
          ?.answer_value as ListValue
      )?.value?.[0]
    : undefined;

  switch (question.question_type) {
    case QUESTION_TYPES_ENUM.boolean:
      return (
        <BooleanButtons
          question={question}
          value={value as Value}
          handleChange={handleChange}
        />
      );
    case QUESTION_TYPES_ENUM.checkbox:
      return (
        <Checkbox
          question={question}
          value={value as ListValue}
          handleChange={handleChange}
          setLoader={setLoader}
        />
      );
    case QUESTION_TYPES_ENUM.radio:
      return (
        <Radio
          question={question}
          value={value as ListValue}
          handleChange={handleChange}
          setLoader={setLoader}
        />
      );
    case QUESTION_TYPES_ENUM.date:
      return (
        <Date
          question={question}
          value={value as Value}
          handleChange={handleChange}
          setError={setError}
        />
      );
    case QUESTION_TYPES_ENUM.switch:
      return (
        <Switch
          question={question}
          value={value as Value}
          handleChange={handleChange}
        />
      );
    case QUESTION_TYPES_ENUM.text:
      return (
        <Text
          question={question}
          value={value as Value}
          handleChange={handleChange}
          setError={setError}
        />
      );
    case QUESTION_TYPES_ENUM.select:
      return (
        <Select
          question={question}
          value={value as ListValue}
          handleChange={handleChange}
          setLoader={setLoader}
          filter={filter}
        />
      );
  }
  return null;
}

function BooleanButtons({ handleChange }: FieldProps) {
  const { isDesktop } = useBreakpoint();
  return (
    <Stack
      sx={{
        gap: isDesktop ? '24px' : '16px',
        flexDirection: 'row',
        width: '100%',
      }}
    >
      <LBTButton
        variant="contained"
        onClick={() => handleChange(false)}
        startIcon={<IconDislikeComponent style={IconsStyle.FILLED} />}
        fullWidth
        size="large"
      >
        No
      </LBTButton>
      <LBTButton
        variant="contained"
        onClick={() => handleChange(true)}
        startIcon={<IconLikeComponent style={IconsStyle.FILLED} />}
        fullWidth
        size="large"
      >
        Sì
      </LBTButton>
    </Stack>
  );
}

function Checkbox({
  question,
  value,
  handleChange,
  setLoader,
}: FieldProps & { value: ListValue }) {
  const optionType: OptionType = question.question_meta
    ?.option_type as OptionType;
  const optionId: string = question.question_meta?.options as string;
  const [options, setOptions] = useState<(Domain | Entity)[]>([]);

  const getOptions = async () => {
    setLoader?.((l: number) => l + 1);
    setOptions(await fetchOptions(optionType, optionId));
    setLoader?.((l: number) => l - 1);
  };

  useEffect(() => {
    getOptions();
  }, [optionType, optionId]);

  return (
    <>
      {options.map((option, index) => {
        const checked = Boolean(value?.value?.find(item => item === option.id));
        let label;

        if (optionType === OptionType.domain) label = (option as Domain).value;
        else label = (option as Entity).name;

        return (
          <Fragment key={option.id}>
            <LBTCheckboxButton
              checked={checked}
              label={label as string}
              handleChange={val => {
                if (val)
                  return handleChange({
                    type: optionType,
                    value: [...(value?.value ?? []), option.id],
                  });
                handleChange({
                  type: optionType,
                  value: value?.value?.filter(item => item !== option.id) ?? [],
                });
              }}
              fullWidth
            />
            {index !== options.length - 1 && <LBTSpacer spacing={4} />}
          </Fragment>
        );
      })}
    </>
  );
}

function Radio({
  question,
  value,
  handleChange,
  setLoader,
}: FieldProps & { value: ListValue }) {
  const optionType: OptionType = question.question_meta
    ?.option_type as OptionType;
  const optionId: string = question.question_meta?.options as string;
  const [options, setOptions] = useState<(Domain | Entity)[]>([]);

  const getOptions = async () => {
    setLoader?.((l: number) => l + 1);
    setOptions(await fetchOptions(optionType, optionId));
    setLoader?.((l: number) => l - 1);
  };

  useEffect(() => {
    getOptions();
  }, [optionType, optionId]);

  return (
    <RadioGroup
      value={value?.value?.[0] ?? 0}
      name={`radio-buttons-group${question.id}`}
      onChange={() => {}}
      sx={{ width: '100%' }}
    >
      {options.map((option, index) => {
        let label: string;

        if (optionType === OptionType.domain)
          label = (option as Domain).value as string;
        else label = (option as Entity).name as string;

        return (
          <Fragment key={option.id}>
            <LBTRadioButton
              label={label}
              value={option.id as number}
              currentValue={value?.value?.[0]}
              handleChange={val =>
                handleChange({
                  type: optionType,
                  value: [val],
                })
              }
              fullWidth
            />
            {index !== options.length - 1 && <LBTSpacer spacing={4} isFixed />}
          </Fragment>
        );
      })}
    </RadioGroup>
  );
}

function Date({ question, value, handleChange, setError }: FieldProps) {
  const validation: ValidationDateType | undefined = question?.question_meta
    ?.validation as ValidationDateType | undefined;

  const getMin = () => {
    if (validation) {
      if (validation === VALIDATION_DATE_ENUM.future)
        return dayjs().subtract(1, 'day');
      if (validation === VALIDATION_DATE_ENUM.future_today) return dayjs();
    }
    return undefined;
  };
  const getMax = () => {
    if (validation) {
      if (validation === VALIDATION_DATE_ENUM.past)
        return dayjs().subtract(1, 'day');
      if (validation === VALIDATION_DATE_ENUM.past_today) return dayjs();
    }
    return undefined;
  };

  const minDate = getMin();
  const maxDate = getMax();

  return (
    <LBTDatePicker
      label={question.title as string}
      value={(value as string) ? dayjs(value as string, DATETIME_FORMAT) : null}
      handleChange={val => handleChange(val?.format(DATETIME_FORMAT))}
      minDate={minDate}
      maxDate={maxDate}
      onError={error => {
        if (!error)
          return setError?.(err => err.filter(item => item !== question.id));
        setError?.(err => [...err, question.id!]);
      }}
    />
  );
}

function Switch({ question, value, handleChange }: FieldProps) {
  return (
    <LBTSwitch
      label={question.title}
      description={question.description}
      checked={String(value).toLowerCase() === 'true'}
      onChange={val => handleChange(val)}
      direction="row-reverse"
      hasFullWidth
    />
  );
}

function Text({ question, value, handleChange, setError }: FieldProps) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isChanghingValue, setIsChangingValue] = useState<boolean>(false);
  const validation: ValidationTextType | undefined = question?.question_meta
    ?.validation as ValidationTextType | undefined;
  const hasSpace = question?.question_meta?.has_space as boolean;
  const isCurrency = question?.question_meta?.is_currency as boolean;
  const numericRegex = /^\d*([,]\d{1,})?$/;
  const currencyRegex = /^\d+([,]\d{1,2})?$/;

  const isAlphabetic = (val: string) => {
    for (let i = 0; i < val.length; i++) {
      if (val[i] === ' ' && hasSpace) continue;
      if (!isNaN(Number(val[i]))) return false;
    }
    return true;
  };

  const isAlphanumeric = (val: string) => {
    let letter = false;
    let number = false;
    for (let i = 0; i < val.length; i++) {
      if (val[i] === ' ' && hasSpace) continue;
      if (!isNaN(Number(val[i]))) number = true;
      else letter = true;
      if (letter && number) return true;
    }
    return false;
  };

  const getHelperText = () => {
    if (errorMessage) return errorMessage;
    if (success) return 'Perfetto!';
    return question.hint;
  };

  useEffect(() => {
    if (!isChanghingValue)
      setSuccess(
        !errorMessage &&
          !!value &&
          (question?.question_meta?.has_success as boolean),
      );
  }, [value]);

  return (
    <LBTTextField
      name={question.title}
      label={question.title}
      value={value ?? ''}
      success={success}
      onBlur={() => {
        setSuccess(
          !errorMessage &&
            !!value &&
            (question?.question_meta?.has_success as boolean),
        );
      }}
      onChange={val => {
        setSuccess(false);
        setIsChangingValue(true);
        if (validation && val) {
          if (
            validation === VALIDATION_TEXT_ENUM.alphabetic &&
            !isAlphabetic(val)
          ) {
            setErrorMessage(
              'Questo formato non è valido. Puoi utilizzare solo delle lettere',
            );
            setError?.(err => [...err, question.id!]);
          } else if (
            validation === VALIDATION_TEXT_ENUM.alphanumeric &&
            !isAlphanumeric(val)
          ) {
            setErrorMessage(
              'Questo valore non è valido. Puoi utilizzare solo numeri e lettere',
            );
            setError?.(err => [...err, question.id!]);
          } else if (
            validation === VALIDATION_TEXT_ENUM.numberic &&
            isCurrency &&
            !currencyRegex.test(val)
          ) {
            setErrorMessage(
              'Questo valore non è valido. Puoi utilizzare solo dei numeri e al massimo 2 cifre decimali',
            );
            setError?.(err => [...err, question.id!]);
          } else if (
            validation === VALIDATION_TEXT_ENUM.numberic &&
            !isCurrency &&
            !numericRegex.test(val)
          ) {
            setErrorMessage(
              'Questo valore non è valido. Puoi utilizzare solo dei numeri',
            );
            setError?.(err => [...err, question.id!]);
          } else if (validation === VALIDATION_TEXT_ENUM.lowercase)
            return handleChange(val.toLowerCase());
          else if (validation === VALIDATION_TEXT_ENUM.uppercase)
            return handleChange(val.toUpperCase());
          else {
            setErrorMessage('');
            setError?.(err => err.filter(item => item !== question.id));
          }
        }
        handleChange(val);
      }}
      error={!!errorMessage}
      helperText={getHelperText()}
      hasHint={!!question.hint}
      required={question.required}
      endIcon={
        isCurrency ? <LBTLabel variant={'inputFormLabel'}>€</LBTLabel> : <></>
      }
      hasAsterisk={false}
    />
  );
}

function Select({
  question,
  value,
  handleChange,
  setLoader,
  filter,
}: FieldProps & { value: ListValue }) {
  const optionType: OptionType = question.question_meta
    ?.option_type as OptionType;
  const optionId: string = question.question_meta?.options as string;
  const isMultiple = Boolean(question?.question_meta?.is_multiple);
  const hasDescription = Boolean(
    question?.question_meta?.has_option_description,
  );
  const hasAutocomplete = Boolean(question?.question_meta?.has_autocomplete);
  const [options, setOptions] = useState<(Domain | Entity)[]>([]);

  const getOptions = async () => {
    setLoader?.((l: number) => l + 1);
    const res = await fetchOptions(optionType, optionId, filter);
    setOptions(res);
    if (
      value?.value &&
      !res.find(item => value.value.find(val => val === item.id))
    )
      handleChange(null);
    setLoader?.((l: number) => l - 1);
  };

  useEffect(() => {
    if (filter || !question?.question_meta?.option_filter) getOptions();
    else {
      setOptions([]);
      if (value?.value) handleChange(null);
    }
  }, [optionType, optionId, filter]);

  if (!hasAutocomplete) {
    return (
      <LBTSelect
        name={question.title}
        label={question.title}
        value={isMultiple ? value?.value ?? [] : value?.value?.[0] ?? undefined}
        disabled={!filter && !!question?.question_meta?.option_filter}
        items={options.map(option => {
          const id = option.id;
          let label;
          let description = undefined;
          let domainName = undefined;
          if (optionType === OptionType.domain) {
            label = (option as Domain).value;
            domainName = (option as Domain).domain;
          } else label = (option as Entity).name;
          //TODO supporto descrizione opzioni
          if (hasDescription) description = 'desc';

          if (optionType === OptionType.domain) {
            if (optionId === domainName) {
              return {
                id: id!,
                name: label!,
                description,
                is_pinned: (option as Domain).is_pinned,
              };
            }
          } else {
            return {
              id: id!,
              name: label!,
              description,
            };
          }
          return undefined;
        })}
        handleChange={val => {
          if (isMultiple)
            return handleChange({
              type: optionType,
              value: val,
            });
          handleChange({
            type: optionType,
            value: [val],
          });
        }}
        multiple={isMultiple}
        helperText={question.hint}
        required={question.required}
        hasAsterisk={false}
      />
    );
  }

  return (
    <LBTAutocomplete
      id={question.title}
      name={question.title}
      label={question.title}
      value={isMultiple ? value?.value ?? [] : value?.value?.[0] ?? undefined}
      disabled={!filter && !!question?.question_meta?.option_filter}
      items={[
        ...options.reduce<any>(
          (prev: Domain[] | Entity[], current: Domain | Entity) => {
            const id = current.id;
            let label;
            let pinned: boolean | undefined;
            if (optionType === OptionType.domain) {
              label = (current as Domain).value;
              pinned = (current as Domain).is_pinned;
            } else {
              pinned = false;
              label = (current as Entity).name;
            }

            return [
              ...prev,
              {
                id: id!,
                name: label!,
                is_pinned: pinned!,
              },
            ];
          },
          [],
        ),
      ]}
      handleChange={val => {
        if (isMultiple)
          return handleChange({
            type: optionType,
            value: val,
          });
        handleChange({
          type: optionType,
          value: [val],
        });
      }}
      multiple={isMultiple}
      getItems={async () => {}}
      required={question.required}
      helperText={question.hint}
      hasAsterisk={false}
    />
  );
}
