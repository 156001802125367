import {
  Misura,
  getAllMisureCallback,
  measuresState,
  searchMisuraCallback,
  useBreakpoint,
  useTrackAnalytics,
} from '@laborability/commons';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import {
  CardVariant,
  COLORS,
  IconSearchComponent,
  IconsSize,
  IconsStyle,
  LBTCard,
  LBTLabel,
  LBTSpacer,
  LBTTextField,
  Section,
} from '@laborability/components';
import { Grid } from '@mui/material';
import { APP_ROUTES } from '../../Routes';
import { useEffect, useRef, useState } from 'react';

export default function SearchMisure() {
  const navigate = useNavigate();
  const fetchMisure = useRecoilCallback(searchMisuraCallback, []);
  const fetchAllMisure = useRecoilCallback(getAllMisureCallback, []);
  // const misure = useRecoilValue(measuresState);
  const [misure, setMisure] = useState<Misura[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [hoverStates, setHoverStates] = useState<{ [key: number]: boolean }>(
    {},
  );
  const { isDesktop } = useBreakpoint();

  const [showComponent, setShowComponent] = useState(false);

  const handleButtonClick = () => {
    setShowComponent(true);
  };

  const getMisure = async (
    skip: number,
    limit: number,
    progressive: boolean,
    params?: { query?: string },
  ) => {
    const res = await fetchMisure({
      query: params?.query ?? '',
      skip,
      limit,
      published: true,
      progressive,
    });
    if (!res || !res.data) return [];

    res.data.items.forEach((item: Misura) => {
      setMisure(misure => [...misure, item]);
    });

    return res.data;
  };

  const getAllMisureCount = async () => {
    const res = await fetchAllMisure({});
    if (!res || !res.data) return [];

    setTotalItems(res.data.total);
  };

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Ricerca libera' },
    { key: 'page_location', value: '/app/ricerca-libera' },
  ]);

  useEffect(() => {
    getAllMisureCount();
  }, []);

  useEffect(() => {
    setMisure([]);
  }, [inputRef.current?.value]);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Cerca tra tutti i bonus
      </LBTLabel>
      <LBTSpacer spacing={6} />
      <LBTTextField
        ref={inputRef}
        datatestid="wide_search_input_field"
        sx={{ maxWidth: '504px' }}
        onKeyUp={event => {
          if (event.key === 'Enter') {
            setSearch(inputRef.current?.value ?? undefined);
            handleButtonClick();
          }
        }}
        placeholder="Cerca"
        onChange={() => {}}
        startIcon={
          <IconSearchComponent
            datatestid="wide_search_result_link"
            size={IconsSize.MEDIUM}
            style={IconsStyle.OUTLINE}
            color={COLORS.getInstance().BLACK}
          />
        }
      />
      <LBTSpacer spacing={2} />
      {showComponent ? (
        <InfiniteDiscover
          items={misure}
          getMoreItems={getMisure}
          params={{ query: search }}
          hasTotalItemsLabel
          customLabelDescription=" totali"
          renderItem={(item: Misura) => {
            const hasHover = item.id ? hoverStates[item.id] || false : false;
            return (
              <Grid item mobile={12} desktop={6} display="flex">
                <LBTCard
                  onClick={() =>
                    navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
                  }
                  backgroundColor={COLORS.getInstance().STATUS_INFO_BLUE_MAIN}
                  title={item.name}
                  description={item.description}
                  hasHover={hasHover}
                  onTouchStart={() => {
                    setHoverStates(prev => {
                      const newState = Object.keys(prev).reduce(
                        (acc: { [key: string]: boolean }, key: string) => {
                          acc[key] = false;
                          return acc;
                        },
                        {},
                      );
                      return {
                        ...newState,
                        [item.id!]: true,
                      };
                    });
                  }}
                  onTouchEnd={() => {
                    setHoverStates(prev => ({
                      ...prev,
                      [item.id!]: false,
                    }));
                  }}
                  onMouseEnter={() => {
                    setHoverStates(prev => ({
                      ...prev,
                      [item.id!]: true,
                    }));
                  }}
                  onMouseLeave={() => {
                    setHoverStates(prev => ({
                      ...prev,
                      [item.id!]: false,
                    }));
                  }}
                  tag={item.categories
                    ?.find(category =>
                      item.categories!.length === 2
                        ? !!category.parent_id
                        : category.parent_id === null,
                    )
                    ?.name?.toLowerCase()}
                  news={item.news ?? false}
                  expired={item.expired ?? false}
                  expiringSoon={item.expiring_soon ?? false}
                  cardVariant={CardVariant.CONTAINED}
                  upcoming={item.upcoming ?? false}
                />
              </Grid>
            );
          }}
        />
      ) : (
        <LBTLabel
          variant="sourceCaption"
          component="small"
          maxWidth={isDesktop ? '1032px' : undefined}
        >
          {`${totalItems} risultati tra cui cercare`}
        </LBTLabel>
      )}
    </Section>
  );
}
