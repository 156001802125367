import {
  checkQuestionaryAccess,
  currentUserState,
  snackBarState,
  useKeycloak,
  useStartup,
} from '@laborability/commons';
import md5 from 'md5';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Outlet } from 'react-router-dom';
import { initializeTagManager } from '../utils/gtm';
import { KeycloakContext } from '@laborability/components';
import { currentCompanyConfigState } from '@laborability/commons';
import Loader from './Loader';
import { useRecoilValue } from 'recoil';

export default function Auth() {
  const { keycloak, init } = useKeycloak();
  const { onStartup, isCompletedStartup } = useStartup();

  const currentCompany = useRecoilValue(currentCompanyConfigState);
  const currentUser = useRecoilValue(currentUserState);

  const { code } = useRecoilValue(snackBarState);

  useEffect(() => {
    if (code && code === 401) keycloak?.logout();
  }, [code]);

  useEffect(() => {
    // initializeTagManager();
    init();
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      onStartup();
    }
  }, [keycloak?.tokenParsed]);

  useEffect(() => {
    if (keycloak?.tokenParsed) {
      if (currentUser) {
        const dataLayer = {
          event: 'login',
          id_user: md5(keycloak.tokenParsed.preferred_username),
          company: `company-${currentUser.companies && currentUser.companies.length > 0 ? currentUser.companies[0].id : 'id'}`,
          company_name:
            currentUser.companies && currentUser.companies.length > 0
              ? currentUser.companies[0].name
              : 'debug',
          timestamp: new Date().toISOString(),
          is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
        };

        window.dataLayer.push(dataLayer);
        console.log('@@@tagManager', dataLayer);
      }
    } else {
      const dataLayer = {
        event: 'page_view',
        page_title: 'Accesso',
        page_location: window.location.href,
        timestamp: new Date().toISOString(),
        is_prod_env: process.env.REACT_APP_ENV === 'prod' ? 'true' : 'false',
      };
      window.dataLayer.push(dataLayer);
      console.log('@@@tagManager', dataLayer);
    }
  }, [currentCompany.id]);

  return (
    <KeycloakContext.Provider
      value={{
        keycloak,
        hasQuestionaryAccess: checkQuestionaryAccess(
          currentCompany.realm ?? '',
        ),
      }}
    >
      {isCompletedStartup ? <Outlet /> : <Loader />}
    </KeycloakContext.Provider>
  );
}
