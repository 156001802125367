import { RuleGroupType } from 'react-querybuilder';
import { Id } from './common';
import { EntityType } from './pageAction';

export interface QuestionRule {
  id?: number;
  question_id?: number | string;
  name?: string;
  execution_order?: number;
  schema?: RuleGroupType | null;
}

export class QuestionRuleClass implements QuestionRule {
  id = 0;
  question_id = 0;
  name = '';
  execution_order = undefined;
  schema = null;
}

export interface Question {
  id?: number;
  title?: string;
  description?: string;
  hint?: string;
  question_type?: QuestionType;
  question_meta?: { [x: string]: unknown };
  attribute_meta_id?: number;
  required?: boolean;
  display_order?: number;
  visible?: boolean;
  flow_id?: number;
  page_id?: number;
  entity_type?: EntityType;
  multi_entity?: boolean;
}

export interface PostQuestion extends Question {
  page_id: number;
}

export interface GetQuestionRule extends Id {
  question_id: number | string;
}

export class QuestionClass implements Question {
  id = 0;
  title = '';
  description = '';
  hint = '';
  question_type = 'text' as QuestionType;
  question_meta = undefined;
  attribute_meta_id = undefined;
  required = false;
  display_order = undefined;
  entity_type = undefined;
  multi_entity = false;
}

export const QUESTION_TYPES = [
  'text',
  'select',
  'date',
  'boolean',
  'radio',
  'checkbox',
  'switch',
] as const;

export enum QUESTION_TYPES_ENUM {
  text = 'text',
  select = 'select',
  date = 'date',
  boolean = 'boolean',
  radio = 'radio',
  checkbox = 'checkbox',
  switch = 'switch',
}

export type QuestionType = (typeof QUESTION_TYPES)[number];

export const VALIDATION_TEXT = [
  'alphabetic',
  'numberic',
  'alphanumeric',
  'uppercase',
  'lowercase',
] as const;

export enum VALIDATION_TEXT_ENUM {
  alphabetic = 'alphabetic',
  numberic = 'numberic',
  alphanumeric = 'alphanumeric',
  uppercase = 'uppercase',
  lowercase = 'lowercase',
}

export type ValidationTextType = (typeof VALIDATION_TEXT)[number];

export const VALIDATION_DATE = [
  'past',
  'past_today',
  'future',
  'future_today',
] as const;

export enum VALIDATION_DATE_ENUM {
  past = 'past',
  past_today = 'past_today',
  future = 'future',
  future_today = 'future_today',
}

export type ValidationDateType = (typeof VALIDATION_DATE)[number];

export enum OptionType {
  domain = 'domain',
  entity = 'entity',
}

export interface EvalQuestion {
  question_id: number;
  entity_id: number;
  answer_value:
    | string
    | number
    | boolean
    | { type: OptionType; value: number[] };
}

export interface EvalAction {
  flow_id: number;
  action_id: number;
  page_id: number;
  questions: EvalQuestion[];
}

export interface UpdateVisibility {
  flow_id: number;
  page_id: number;
  questions: EvalQuestion[];
}

export interface ContinueFlow {
  flow_id: number;
  page_id: number;
}

export interface UserAnswer {
  question_id: number;
  attribute_name: string;
  label: string;
  answer: string;
  entity_id: number;
  answer_type: string;
}

export interface UserAnswersPage {
  page_id: number;
  page_name: string;
  answers: UserAnswer[];
  page_order: number;
}

export interface UserAnswersFlow {
  flow_id: number;
  step: number;
  image?: string;
  position: number;
  number_of_pages: number;
  flow_name: string;
  total_pages: number;
  completed_pages: number;
  pages: UserAnswersPage[];
}

export interface UserAnswers {
  user_id: number;
  flows: UserAnswersFlow[];
}

export interface FormattedAnswer {
  id: string;
  flow: string;
  page: string;
  question: string;
  answer: string;
}

export class UserAnswersClass implements UserAnswers {
  user_id = 0;
  flows = [];
}
