import { Box, InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import IconWarningComponent from '../Icons/IconWarningComponent';
import { COLORS } from '../../utils/Colors';
import { IconsSize, IconsStyle } from '../../enums';
import { IconSuccess } from '../Icons';

export type LBTTextFieldProps = Omit<
  TextFieldProps,
  'onChange' | 'helperText'
> & {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  datatestid?: string;
  onChange: (val?: string) => void;
  hasAsterisk?: boolean;
  success?: boolean;
  hasHint?: boolean;
  helperText?: ReactNode;
};

const StyledTextField = styled(TextField)<
  TextFieldProps & {
    datatestid?: string;
    'data-track': string;
    hasAutoHeight?: boolean;
    success?: boolean;
  }
>(({ hasAutoHeight, success, ...props }) => ({
  datatestid: props.datatestid,
  'data-track': props['data-track'],
  height: hasAutoHeight ? 'auto !important' : '56px',
  '& .MuiFormHelperText-root': {
    width: '100%',
    marginRight: 0,
    color: success
      ? `${COLORS.getInstance().SUCCESS_SUPERDARK} !important`
      : '',
  },
}));

const LBTTextField = forwardRef<HTMLInputElement, LBTTextFieldProps>(
  (
    {
      startIcon,
      endIcon,
      datatestid = '',
      onChange,
      disabled,
      hasAsterisk = true,
      success = false,
      hasHint = false,
      helperText,
      ...props
    }: LBTTextFieldProps,
    ref,
  ) => {
    const hasAutoHeight = hasHint;
    return (
      <StyledTextField
        hasAutoHeight={hasAutoHeight}
        inputRef={ref}
        fullWidth
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
          style: {
            backgroundColor: disabled
              ? COLORS.getInstance().BW_GREYS_IPERLIGHT
              : undefined,
          },
        }}
        InputLabelProps={{ required: hasAsterisk }}
        datatestid={`lbt-text-field-${datatestid}`}
        data-track={datatestid}
        onChange={e => onChange?.(e.target.value)}
        helperText={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {props.error && (
              <IconWarningComponent
                size={IconsSize.SMALL}
                style={IconsStyle.OUTLINE}
                color={COLORS.getInstance().ERROR_MAIN}
              />
            )}
            {success && (
              <IconSuccess
                size={IconsSize.SMALL}
                color={COLORS.getInstance().SUCCESS_SUPERDARK}
              />
            )}

            {helperText}
          </Box>
        }
        disabled={disabled}
        success={success}
        {...props}
      />
    );
  },
);

export default LBTTextField;
