import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import {
  PickersLayoutProps,
  usePickerLayout,
  pickersLayoutClasses,
  PickersLayoutRoot,
  PickersLayoutContentWrapper,
} from '@mui/x-date-pickers/PickersLayout';
import { DateView } from '@mui/x-date-pickers/models';
import {
  DatePicker,
  DatePickerProps,
  DatePickerToolbarProps,
  PickersCalendarHeaderProps,
} from '@mui/x-date-pickers';
import { LBTButton } from '../Button';
import { Grid, IconButton, Stack, styled } from '@mui/material';
import {
  IconArrowLeftComponent,
  IconArrowRightComponent,
  IconCalendarComponent,
} from '../Icons';
import { IconsSize, IconsStyle } from '../../enums';
import { COLORS, FONTS } from '../../utils';
import LBTLabel from '../Label';
import 'dayjs/locale/it';
import { useBreakpoint } from '@laborability/commons';
import LBTSpacer from '../Spacer';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function CustomButton(props: any) {
  const CustomButtonRoot = styled(IconButton)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    borderColor: COLORS.getInstance().PRIMARY_DARK,
    flexShrink: '0',
    height: '48px',
    width: '41px',

    '&:hover': {
      backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
    },
    '&:active': {
      backgroundColor: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      outline: '1px solid',
      outlineColor: COLORS.getInstance().PRIMARY_SUPERDARK,
    },
  });

  return (
    <CustomButtonRoot onClick={props.onClick}>
      <IconCalendarComponent
        style={IconsStyle.FILLED}
        size={IconsSize.MEDIUM}
        color={COLORS.getInstance().PRIMARY_DARK}
      />
    </CustomButtonRoot>
  );
}

function CustomHeader(props: PickersCalendarHeaderProps<Dayjs>) {
  const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
    alignItems: 'center',
  });

  const CustomIconButton = styled(IconButton)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    borderColor: COLORS.getInstance().PRIMARY_DARK,
    height: '44px',
    width: '52px',

    '&:hover': {
      backgroundColor: COLORS.getInstance().PRIMARY_IPERLIGHT,
    },
    '&:active': {
      backgroundColor: COLORS.getInstance().PRIMARY_SUPERLIGHT,
      outline: '1px solid',
      outlineColor: COLORS.getInstance().PRIMARY_SUPERDARK,
    },
  });

  const { currentMonth, view, onMonthChange, onViewChange } = props;
  const { isDesktop } = useBreakpoint();
  const goNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
  const goPreviousMonth = () => {
    onMonthChange(currentMonth.subtract(1, 'month'), 'right');
  };

  const label = () => {
    if (view === 'year') {
      return 'Seleziona un anno';
    } else if (view === 'month') {
      return capitalizeFirstLetter(currentMonth.locale('it').format('YYYY'));
    } else if (view === 'day') {
      return capitalizeFirstLetter(
        currentMonth.locale('it').format(isDesktop ? 'MMMM' : 'MMMM YYYY'),
      );
    }
  };

  return (
    <CustomCalendarHeaderRoot>
      <Stack direction="row">
        <CustomIconButton onClick={goPreviousMonth}>
          <IconArrowLeftComponent
            style={IconsStyle.FILLED}
            size={IconsSize.MEDIUM}
            color={COLORS.getInstance().PRIMARY_MAIN}
          />
        </CustomIconButton>
      </Stack>
      <LBTButton
        variant="invisible"
        color="primary"
        size="medium"
        textProps={{ color: COLORS.getInstance().PRIMARY_SUPERDARK }}
        onClick={() => {
          if (view === 'day') {
            onViewChange && onViewChange('month');
          } else if (view === 'month') {
            onViewChange && onViewChange('year');
          }
        }}
      >
        {label()}
      </LBTButton>
      <Stack spacing={1} direction="row">
        <CustomIconButton onClick={goNextMonth}>
          <IconArrowRightComponent
            style={IconsStyle.FILLED}
            size={IconsSize.MEDIUM}
            color={COLORS.getInstance().PRIMARY_MAIN}
          />
        </CustomIconButton>
      </Stack>
    </CustomCalendarHeaderRoot>
  );
}

function CustomActions(props: PickersActionBarProps) {
  const CustomGrid = styled(Grid)({
    paddingBottom: '24px',
    paddingRight: '24px',
  });

  const { onAccept, onCancel } = props;

  return (
    <CustomGrid container>
      <Grid item mobile={12} desktop={12}>
        <Grid container justifyContent="right">
          <Grid item mobile={8} desktop={12}>
            <Stack direction={'row'} spacing="8px">
              <LBTButton
                size="small"
                variant="invisible"
                fullWidth
                onClick={onCancel}
              >
                Annulla
              </LBTButton>
              <LBTButton
                size="small"
                variant="outlined"
                fullWidth
                onClick={onAccept}
              >
                Conferma
              </LBTButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </CustomGrid>
  );
}

function CustomToolbar(props: DatePickerToolbarProps<Dayjs>) {
  const CustomStack = styled(Stack)({
    paddingTop: '24px',
    paddingLeft: '24px',
  });

  return (
    <CustomStack className={props.className}>
      <LBTLabel
        color={`${COLORS.getInstance().BLACK}99`}
        textAlign="left"
        variant={'datepickerTitle'}
      >
        SELEZIONA UNA DATA
      </LBTLabel>

      <LBTLabel
        sx={{ minHeight: '40px' }}
        color={`${COLORS.getInstance().BLACK}E5`}
        textAlign="left"
        variant={'datepickerSubtitle'}
      >
        {props.value?.format('DD MMM YYYY')}
      </LBTLabel>
    </CustomStack>
  );
}

function CustomLayout(
  props: PickersLayoutProps<Dayjs | null, Dayjs, DateView>,
) {
  const { toolbar, content, actionBar } = usePickerLayout(props);
  const { isDesktop } = useBreakpoint();

  return (
    <PickersLayoutRoot ownerState={props}>
      {!isDesktop && toolbar}
      <PickersLayoutContentWrapper
        className={pickersLayoutClasses.contentWrapper}
      >
        <LBTSpacer spacing={3} isFixed />
        {content}
        <LBTSpacer spacing={3} isFixed />
        {!isDesktop && actionBar}
      </PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
}

interface Props
  extends Omit<DatePickerProps<Dayjs>, 'value' | 'minDate' | 'maxDate'> {
  value?: Dayjs | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  label: string;
  handleChange: (val?: Dayjs) => void;
}

export default function LBTDatePicker({
  value,
  label,
  handleChange,
  minDate,
  maxDate,
  disabled,
  ...props
}: Props) {
  const { isDesktop } = useBreakpoint();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DatePicker
        label={label}
        views={['year', 'month', 'day']}
        value={value ? value : value === null ? null : undefined}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : undefined}
        onChange={val => handleChange(val ? val : undefined)}
        slotProps={{
          day: {
            sx: {
              '&.MuiPickersDay-root.Mui-selected': {
                fontFamily: FONTS.SOURCE_SANS_PRO_BOLD,
                backgroundColor: COLORS.getInstance().PRIMARY_MAIN,
                color: COLORS.getInstance().WHITE,
              },
              '&.MuiPickersDay-today': {
                backgroundColor: 'transparent',
                borderColor: COLORS.getInstance().PRIMARY_MAIN,
                borderWidth: '2px',
              },
              '&.MuiPickersDay-today.Mui-selected': {
                backgroundColor: COLORS.getInstance().PRIMARY_MAIN,
                outline: '2px solid',
                outlineColor: COLORS.getInstance().PRIMARY_MAIN,
                outlineOffset: '1px',
              },
            },
          },
          mobilePaper: {
            style: {
              borderRadius: '16px',
            },
          },
          desktopPaper: {
            style: {
              borderRadius: '16px',
            },
          },
          toolbar: {
            toolbarFormat: 'DD MMM YYYY',
          },
          textField: {
            InputProps: {
              style: {
                backgroundColor: disabled
                  ? COLORS.getInstance().BW_GREYS_IPERLIGHT
                  : undefined,
                paddingRight: '3px',
              },
            },
          },
        }}
        slots={{
          calendarHeader: CustomHeader,
          layout: CustomLayout,
          actionBar: CustomActions,
          toolbar: CustomToolbar,
          openPickerButton: CustomButton,
        }}
        sx={{
          width: '100%',
        }}
        disabled={disabled}
        closeOnSelect={isDesktop ? true : false}
        {...props}
      />
    </LocalizationProvider>
  );
}
