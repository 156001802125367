import {
  QUESTIONARY_STEPS,
  UserAnswersFlow,
  currentUserState,
  getAnswersCallback,
  getUserProfileCallback,
  useBreakpoint,
  userAnswersLoaderState,
  userAnswersState,
  userLoaderState,
  useTrackAnalytics,
  STEP3_REQUIRED_ANSWER,
  UserAnswers,
  QuestionPage,
  getAllQuestionPageCallback,
  ActionTypeEnum,
  slugify,
} from '@laborability/commons';
import {
  IconArrowRightComponent,
  IconQuestionaryStep,
  IconWarningComponent,
  IconsStyle,
  LBTButton,
  LBTChip,
  LBTImage,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { APP_ROUTES } from '../../Routes';
import AnswerReview from './AnswerReview';

export default function QuestionaryMenu() {
  const { isDesktop } = useBreakpoint();
  const user = useRecoilValue(currentUserState);
  const answers = useRecoilValue(userAnswersState);
  const [selectedFlowId, setSelectedFlowId] = useState<number>(0);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const getAnswers = useRecoilCallback(getAnswersCallback, []);
  const userLoader = useRecoilValue(userLoaderState);
  const currentUser = useRecoilValue(currentUserState);
  const answerLoader = useRecoilValue(userAnswersLoaderState);
  const navigate = useNavigate();
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);
  // const isQuestionaryCompleted =
  //   !userLoader &&
  //   !answerLoader &&
  //   answers.flows.length === QUESTIONARY_STEPS &&
  //   !answers.flows.find(flow => flow.position !== flow.number_of_pages);

  const isQuestionaryCompleted =
    currentUser.questionary_status?.length === QUESTIONARY_STEPS &&
    currentUser.questionary_status?.every(
      questionary => questionary.completed === true,
    );

  const isStepCompleted = (step: number) => {
    return currentUser.questionary_status?.some(
      questionary => questionary.completed && questionary.step === step,
    );
  };

  const getPages = async (id: number) => {
    const res = await getAllPage({ id: id });
    const questionPage: QuestionPage[] = res?.data.items;
    const resolvedQuestionPages = await Promise.all(questionPage);
    const pages = resolvedQuestionPages.flat();
    return pages as QuestionPage[];
  };

  const getAllAnswers = async (step: number) => {
    const res = await getAnswers();
    const answers: UserAnswers = res?.data;
    return answers.flows.find(flow => flow.step === step);
  };

  async function resumeQuestionary() {
    const firstSteps = [1, 2, 3];

    const startedSteps =
      currentUser.questionary_status
        ?.map(item => ({
          step: item.step,
          completed: item.completed,
        }))
        .sort((a, b) => a.step! - b.step!) ?? [];

    const missingSteps: number[] = [];

    for (let step = 1; step <= firstSteps.length; step++) {
      if (startedSteps.map(item => item.step).indexOf(step) === -1) {
        missingSteps.push(step);
      }
    }

    missingSteps.sort((a, b) => a - b);

    const unfinshedSteps = await Promise.all(
      currentUser.questionary_status
        ?.filter(item =>
          item.step === 3
            ? item.total_responses < STEP3_REQUIRED_ANSWER
            : item.completed === false,
        )
        .map(async item => {
          if (item.step === 3) {
            if (
              !currentUser.questionary_status?.find(flow => flow.step === 3)
                ?.total_responses
            ) {
              return {
                step: item.step,
                pageId: 0,
              };
            }
            const pages = await getPages(
              currentUser.questionary_status?.find(flow => flow.step === 3)
                ?.flow_id!,
            );
            const skipPages = pages.filter(page =>
              page.actions?.some(
                action => action.action_type === ActionTypeEnum.skip,
              ),
            );
            const answers = await getAllAnswers(item.step);
            const unrespondedAnswers = skipPages
              .filter(
                page =>
                  !answers?.pages
                    .map(answer => answer.page_id)
                    .includes(page.id!),
              )
              .sort((a, b) => a.page_order! - b.page_order!);
            if (unrespondedAnswers.length) {
              return {
                step: item.step,
                pageId: unrespondedAnswers[0].id,
              };
            }
          }
          return {
            step: item.step ?? 1,
            pageId: item.page_id,
          };
        }) ?? [],
    );

    unfinshedSteps.sort((a, b) => a.step! - b.step!);

    const isFirstStepsMissing = firstSteps.some(item =>
      missingSteps.includes(item),
    );
    const isFirstStepsUnfinished = firstSteps.some(item =>
      unfinshedSteps.map(item => item.step).includes(item),
    );

    if (startedSteps.length === 0) {
      /// utente non ha iniziato nessun questionario
      navigate(`/${APP_ROUTES.QUESTIONARY}`); /// inizia il questionario da zero
    } else {
      /// utente ha iniziato almeno un questionario
      if (missingSteps.length > 0) {
        /// utente ha saltato dei questionari
        if (isFirstStepsMissing) {
          /// uno dei questionari saltati è Q1, Q2 o Q3
          if (unfinshedSteps.length > 0 && isFirstStepsUnfinished) {
            /// utente ha un questionario tra Q1, Q2 o Q3 incompleto
            navigate(`/${APP_ROUTES.QUESTIONARY}`, {
              state: {
                step: unfinshedSteps[0].step,
                pageId: unfinshedSteps[0].pageId,
              },
            }); /// parti dal primo questionario incompleto tra Q1, Q2 o Q3
          } else {
            /// utente non ha nessun questionario tra Q1, Q2 o Q3 incompleto, solo saltati
            navigate(`/${APP_ROUTES.QUESTIONARY}`, {
              state: {
                step: missingSteps[0],
                pageId: 0,
              },
            }); /// vai al primo questionario saltato
          }
        } else {
          /// uno dei questionari saltati non è Q1, Q2 o Q3
          if (unfinshedSteps.length > 0) {
            /// utente ha dei questionari incompleti
            navigate(`/${APP_ROUTES.QUESTIONARY}`, {
              state: {
                step: unfinshedSteps[0].step,
                pageId: unfinshedSteps[0].pageId,
              },
            }); /// vai al primo questionario incompleto
          } else {
            /// utente non ha nessun questionario incompleto, solo saltati
            navigate(`/${APP_ROUTES.QUESTIONARY}`, {
              state: {
                step: missingSteps[0],
                pageId: 0,
              },
            }); /// vai al primo questionario saltato
          }
        }
      } else {
        /// utente non ha saltato nessun questionario
        if (unfinshedSteps.length > 0) {
          /// utente ha dei questionari incompleti
          navigate(`/${APP_ROUTES.QUESTIONARY}`, {
            state: {
              step: unfinshedSteps[0].step,
              pageId: unfinshedSteps[0].pageId,
            },
          }); /// vai al primo questionario incompleto
        } else {
          navigate(`/${APP_ROUTES.QUESTIONARY}`, {
            state: {
              step: 0,
              pageId: 0,
            },
          }); /// vai al menu del questionario
        }
      }
    }
  }

  useEffect(() => {
    getUserMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Il tuo questionario' },
    { key: 'page_location', value: '/menu/tuo-questionario/' },
  ]);

  useEffect(() => {
    getAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  console.log('###answers', answers);
  console.log('###user', currentUser.questionary_status);

  const selectedFlow = answers.flows.find(
    (flow: UserAnswersFlow) => flow.flow_id === selectedFlowId,
  );
  if (selectedFlow)
    return (
      <AnswerReview
        flow={selectedFlow}
        onClose={() => {
          setSelectedFlowId(0);
          getAnswers();
          getUserMe();
        }}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Questionario
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Puoi modificare le risposte o continuare a rispondere se non hai
          ancora finito
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <Box
          component="ul"
          maxWidth="680px"
          width="100%"
          padding={0}
          margin={0}
        >
          {answers.flows
            .slice()
            .sort((a, b) => a.step - b.step)
            .map((flow: UserAnswersFlow) => (
              <LBTListItem
                datatestid={`menu_agevolazioni_${slugify(flow.flow_name)}`}
                key={flow.flow_id}
                component="a"
                title={flow.flow_name}
                onClick={() => setSelectedFlowId(flow.flow_id)}
                leftItem={<IconQuestionaryStep step={flow.step} />}
                rightItem={
                  <Stack flexDirection="row" alignItems="center">
                    {flow.step === 3
                      ? flow.completed_pages < STEP3_REQUIRED_ANSWER && (
                          <LBTChip
                            icon={
                              <IconWarningComponent
                                style={IconsStyle.OUTLINE}
                              />
                            }
                            color="tangerine"
                            sx={{ minWidth: '50px', marginRight: '10px' }}
                          />
                        )
                      : !isStepCompleted(flow.step) && (
                          <LBTChip
                            label={`${Math.round((flow.completed_pages * 100) / flow.total_pages)}%`}
                            color="lemonCake"
                            sx={{ minWidth: '50px', marginRight: '10px' }}
                          />
                        )}
                    <IconArrowRightComponent />
                  </Stack>
                }
                sx={{ minHeight: '58px' }}
              />
            ))}
        </Box>
        <LBTSpacer spacing={4} />
        {!isQuestionaryCompleted && (
          <LBTButton
            variant="contained"
            fullWidth
            onClick={() => {
              resumeQuestionary();
            }}
            maxWidth="504px"
            size="large"
          >
            Continua a rispondere
          </LBTButton>
        )}
        <LBTSpacer spacing={8} isFixed />
      </Section>

      {isQuestionaryCompleted && (
        <div
          style={{
            width: '100%',
            height: isDesktop ? '514px' : '360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage:
              'radial-gradient(181.73% 86.53% at 51.79% 90.97%, #59A5FF 29%, rgba(255, 255, 255, 0.05) 100%)',
          }}
        >
          <LBTSpacer spacing={4} />
          <LBTLabel variant="smallCapsBold">Incredibile ma vero</LBTLabel>
          <LBTSpacer spacing={1} />
          <LBTLabel variant="spGroteskSubtitle">
            Hai risposto a tutte le domande
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTImage image="astronaut" />
        </div>
      )}
    </div>
  );
}
