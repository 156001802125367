import {
  COLORS,
  LBTCard,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  Misura,
  getMisureByTagCallback,
  getTagByIdCallback,
  measuresState,
  tagState,
  slugify,
  useTrackAnalytics,
  useBreakpoint,
} from '@laborability/commons';
import InfiniteDiscover from '../../components/InfiniteDiscover';
import { APP_ROUTES } from '../../Routes';
import ImageBox from '../../components/ImageBox';

export default function MisuraPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const tag = useRecoilValue(tagState);
  const misure = useRecoilValue(measuresState);
  const [hoverStates, setHoverStates] = useState<{ [key: number]: boolean }>(
    {},
  );
  const { isDesktop } = useBreakpoint();
  const fetchMisure = useRecoilCallback(getMisureByTagCallback, []);
  const getTabById = useRecoilCallback(getTagByIdCallback, []);

  const [tagName, setTagName] = useState<string>();

  const getMisure = async (
    skip: number,
    limit: number,
    progressive: boolean,
  ) => {
    const res = await fetchMisure({
      tag_id: Number(id),
      skip,
      limit,
      published: true,
      progressive,
    });
    if (!res || !res.data) return [];
    return res.data;
  };

  useEffect(() => {
    getTabById({ id: Number(id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tag.id?.toString() === window.location.href.split('/').pop())
      setTagName(tag.name);
  }, [tag]);

  useTrackAnalytics(
    tagName
      ? [
          { key: 'event', value: 'page_view' },
          { key: 'page_title', value: `Tag ${tagName}` },
          { key: 'page_location', value: `/app/tag/${slugify(tagName)}` },
        ]
      : [],
  );

  if (!tag) return null;

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        {tag?.name ?? ''}
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        {tag?.description ?? ''}
      </LBTLabel>
      <LBTSpacer spacing={2} />
      {tag?.image && <ImageBox src={tag.image} style={{ maxWidth: '200px' }} />}
      <LBTSpacer spacing={2} />
      <InfiniteDiscover
        items={misure}
        getMoreItems={getMisure}
        hasTotalItemsLabel
        renderItem={(item: Misura) => {
          const hasHover = item.id ? hoverStates[item.id] || false : false;
          return (
            <Grid item mobile={12} desktop={6} display="flex">
              <LBTCard
                onClick={() =>
                  navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
                }
                backgroundColor={COLORS.getInstance().STATUS_INFO_BLUE_MAIN}
                title={item.name}
                description={item.description}
                tag={item.categories
                  ?.find(category =>
                    item.categories!.length === 2
                      ? !!category.parent_id
                      : category.parent_id === null,
                  )
                  ?.name?.toLowerCase()}
                hasHover={hasHover}
                onTouchStart={() => {
                  setHoverStates(prev => {
                    const newState = Object.keys(prev).reduce(
                      (acc: { [key: string]: boolean }, key: string) => {
                        acc[key] = false;
                        return acc;
                      },
                      {},
                    );
                    return {
                      ...newState,
                      [item.id!]: true,
                    };
                  });
                }}
                onTouchEnd={() => {
                  setHoverStates(prev => ({
                    ...prev,
                    [item.id!]: false,
                  }));
                }}
                onMouseEnter={() => {
                  setHoverStates(prev => ({
                    ...prev,
                    [item.id!]: true,
                  }));
                }}
                onMouseLeave={() => {
                  setHoverStates(prev => ({
                    ...prev,
                    [item.id!]: false,
                  }));
                }}
                news={item.news ?? false}
                expired={item.expired ?? false}
                expiringSoon={item.expiring_soon ?? false}
                upcoming={item.upcoming ?? false}
              />
            </Grid>
          );
        }}
      />
    </Section>
  );
}
