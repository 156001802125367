import {
  APP_ROUTES,
  QUESTIONARY_STEPS,
  QuestionFlow,
  currentUserState,
  initFlowCallback,
  postUserAcceptPrivacyTermsCallback,
  slugify,
  useBreakpoint,
  useLoading,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  LBTAlert,
  LBTButton,
  LBTCheckbox,
  LBTLabel,
  LBTSpacer,
  QuestionaryContext,
  Section,
} from '@laborability/components';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useContext, useEffect, useState } from 'react';
import Page from './Page';
import { Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
  flow: QuestionFlow;
}

export default function Flow({ flow }: Props) {
  const isLoading: boolean = useLoading();
  const { isDesktop } = useBreakpoint();
  const { pageId, setPageId } = useContext(QuestionaryContext);
  const init = useRecoilCallback(initFlowCallback, []);
  const acceptPrivacyTerms = useRecoilCallback(
    postUserAcceptPrivacyTermsCallback,
    [],
  );
  const currentUser = useRecoilValue(currentUserState);
  const position = currentUser.questionary_status?.some(
    questionary => questionary.step === flow.step,
  );
  const [privacyFlag, setPrivacyFlag] = useState<boolean>(
    currentUser.privacy_accepted!,
  );
  const [lastFlowName, setLastFlowName] = useState('');
  const step =
    position || currentUser.questionary_status?.length === 0
      ? flow.step!
      : currentUser.questionary_status!.length + 1;

  const initQuestionary = async (id: number) => {
    const res = await init({ id });
    if (!res?.data?.id) return;
    setPageId(res.data.id);
  };

  const [trackAnalyticsProps, setTrackAnalyticsProps] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  const getTrackAnalyticsProps = () => {
    if (lastFlowName === flow.name) return [];
    if (flow.name && !pageId && !isLoading) {
      setLastFlowName(flow.name);
      return [
        { key: 'event', value: 'page_view' },
        { key: 'page_title', value: `Questionario - ${flow.name}` },
        { key: 'page_location', value: `/onboarding/${slugify(flow.name)}` },
      ];
    }
    return [];
  };

  useTrackAnalytics(!isLoading ? trackAnalyticsProps : []);

  useEffect(() => {
    setTrackAnalyticsProps(getTrackAnalyticsProps());
  }, [flow.name, pageId, lastFlowName]);

  if (!pageId)
    return (
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="sourceSubtitle">
          Passo {step > 3 ? step : flow.step}/{QUESTIONARY_STEPS}
        </LBTLabel>
        <LBTSpacer spacing={1} />
        <LBTLabel variant="delaDisplay">{flow.name}</LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle">{flow.description}</LBTLabel>
        <LBTSpacer spacing={2} />
        {flow?.image && (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '100%',
                maxWidth: !isDesktop ? '200px' : '300px',
              }}
              src={flow.image}
              alt=""
            />
          </Box>
        )}
        <LBTSpacer spacing={2} />
        <LBTAlert
          title={flow?.info_title!}
          message={flow?.info_text!}
          variant="standard"
          color="success"
          sx={{ width: '100%' }}
        />
        <LBTSpacer spacing={4} />
        {flow?.step === 1 && (
          <>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <LBTCheckbox
                checked={privacyFlag}
                onChange={() => {
                  setPrivacyFlag(!privacyFlag);
                }}
              />
              <LBTLabel
                variant="inputFormLabel"
                component="label"
                textAlign="left"
              >
                Ho letto l'
                <Link
                  to={`/${APP_ROUTES.ART_13}`}
                  style={{ color: 'inherit', textDecoration: 'underline' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  replace
                >
                  informativa ex art 13
                </Link>{' '}
                e do il mio consenso
              </LBTLabel>
            </Stack>
            <LBTSpacer spacing={4} />
          </>
        )}

        <LBTButton
          onClick={() => {
            if (flow.step === 1) {
              acceptPrivacyTerms();
            }
            initQuestionary(flow.id!);
          }}
          variant="contained"
          size="large"
          maxWidth="504px"
          fullWidth
          disabled={flow?.step === 1 ? !privacyFlag : false}
        >
          Avanti
        </LBTButton>
        <LBTSpacer spacing={4} />
      </Section>
    );

  return <Page flow={flow} />;
}
