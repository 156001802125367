import { useBreakpoint } from '@laborability/commons';
import {
  COLORS,
  IconCrossComponent,
  IconsSize,
  IconsStyle,
  LBTButton,
  LBTButtonIcon,
  LBTLabel,
} from '@laborability/components';
import { Modal, Paper, Stack } from '@mui/material';

interface Props {
  open: boolean;
  title: string;
  children: string;
  onClose: () => void;
  onSubmit: () => void;
  onCancel?: () => void;
  onSubmitLabel?: string;
  onCancelLabel?: string;

  datatestidClose?: string;
  datatestidSubmit?: string;
  datatestidCancel?: string;
}

export default function LBTDialog({
  open,
  title,
  children,
  onClose,
  onSubmit,
  onCancel,
  onSubmitLabel = 'Conferma',
  onCancelLabel = 'Annulla',
  datatestidClose = '',
  datatestidSubmit = '',
  datatestidCancel = '',
}: Props) {
  const { isDesktop } = useBreakpoint();
  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        style={{
          width: isDesktop ? '489px' : '90%',
          borderRadius: '24px',
        }}
      >
        <Stack direction="column" spacing="24px" padding="16px">
          <Stack
            direction="row"
            alignItems="start"
            justifyContent="space-between"
          >
            <LBTLabel
              style={{ marginRight: '1px' }}
              textAlign="left"
              variant="miscTitleSource"
            >
              {title}
            </LBTLabel>
            <LBTButtonIcon
              datatestid={datatestidClose}
              size="small"
              onClick={onClose}
            >
              <IconCrossComponent
                style={IconsStyle.FILLED}
                size={IconsSize.MEDIUM}
                color={COLORS.getInstance().BW_GREYS_JET_BLACK}
              />
            </LBTButtonIcon>
          </Stack>
          <LBTLabel textAlign="left" variant="miscDescription">
            {children}
          </LBTLabel>
          <Stack direction="row" spacing="16px" justifyContent="flex-end">
            {onCancel && (
              <LBTButton
                datatestid={datatestidCancel}
                variant="invisible"
                size="medium"
                onClick={onCancel}
              >
                {onCancelLabel}
              </LBTButton>
            )}
            <LBTButton
              datatestid={datatestidSubmit}
              variant="contained"
              size="medium"
              onClick={onSubmit}
            >
              {onSubmitLabel}
            </LBTButton>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}
